<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="titleContainer bg-primary">
          <p class="titleText">{{ $t("labels.personal") }}</p>
        </div>
        <div :class="`subContainer ${invalidContent && 'invalid'}`">
          <textarea
            v-model="content"
            class="textContent"
            @input="handleContentInput"
            :placeholder="$t('labels.writeDownTextHere')"
          />
        </div>

        <div class="searchContainer">
          <div class="searchBar shadow">
            <input
              v-model="searchText"
              @input="handleSearchInput"
              class="searchInput"
              :placeholder="$t('labels.writeDownKeyword')"
            />
          </div>
          <div
            class="searchIconContainer bg-primary shadow btn"
            @click="goToSearchResultPage()"
          >
            <font-awesome-icon icon="search" class="searchIcon" />
          </div>
        </div>
        <button class="submitBtn btn" @click="share()">
          {{ $t("labels.save") }}
        </button>
        <div class="quoteContainer">
          <font-awesome-icon icon="quote-left" class="quoteIcon" />
          <p class="quoteText">{{ dailyPhrase }}</p>
        </div>
        <div class="btnRow mt-50">
          <div
            :class="`switchBtnContainer btn shadow ${
              isNote ? 'bg-green' : 'inactive'
            }`"
            @click="setNote(true)"
          >
            <img :src="historiaImage" class="switchBtnImage" />
          </div>
          <div
            :class="`switchBtnContainer btn shadow ${
              isNote ? 'inactive' : 'bg-yellow'
            }`"
            @click="setNote(false)"
          >
            <img :src="lockImage" class="switchBtnImage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CustomHeader from "../components/CustomHeader.vue";
export default {
  name: "PersonalPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      historiaImage: require("../assets/image/svg/history.svg"),
      lockImage: require("../assets/image/svg/personalisimo.svg"),
      isNote: true,
      content: "",
      invalidContent: false,
      searchText: "",
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToOutsideLink(url) {
      window.open(url, "_blank");
    },
    goToMyNotes() {
      this.$router.push("/myNotes");
    },
    setNote(flag) {
      if (this.isNote != flag) {
        this.content = "";
      }
      this.isNote = flag;
    },
    handleContentInput(e) {
      this.invalidContent = !e.target.value;
    },
    share() {
      if (!this.content) {
        this.invalidContent = true;
        return;
      }
      if (this.isNote) {
        this.$store.dispatch("main/addNoteAttempt", this.content);
      } else {
        this.$store.dispatch("main/addDiaryAttempt", this.content);
      }
      this.content = "";
    },
    goToSearchResultPage() {
      this.$router.push(
        `/myNotes?method=${this.isNote ? "note" : "diary"}&searchText=${
          this.searchText
        }`
      );
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
  computed: mapState({
    dailyPhrase: (state) => state.main.dailyPhrase,
  }),
  mounted() {
    this.$store.dispatch("main/getDailyPhraseAttempt");
  },
};
</script>

<style scoped>
.textContent {
  width: 100%;
  border: none;
  background-color: transparent;
  height: 100px;
  resize: none;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  font-size: 15px;
  color: var(--textColor);
}
.searchContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.searchBar {
  display: flex;
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(241, 242, 242);
}
.searchInput {
  width: 100%;
  padding: 0px;
  font-size: 15px;
  color: var(--textColor);
  border: none;
  background-color: transparent;
}
.searchIcon {
  font-size: 15px;
  color: white;
  transform: rotate(90deg);
}
.searchIconContainer {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 30px;
}
.submitBtn {
  background-color: red;
  padding: 10px 20px;
  align-self: flex-start;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: white;
}
.quoteContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
.quoteIcon {
  font-size: 80px;
  color: gray;
}
.quoteText {
  flex-wrap: wrap;
  display: flex;
  flex: 1;
  margin-left: 10px;
  font-size: 25px;
  font-style: italic;
  padding: 0px 10px;
  border-left: 2px solid gray;
  color: var(--textColor);
}
.btnRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.switchBtnContainer {
  margin: 10px;
  overflow: hidden;
  border-radius: 5px;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switchBtnImage {
  width: 60%;
  height: 60%;
}
@media only screen and (max-width: 400px) {
  .switchBtnContainer {
    margin: 3px;
    height: 100px;
    width: 100px;
  }
}
.inactive {
  background-color: lightgray;
}
.invalid {
  border: 1px solid red;
}
</style>